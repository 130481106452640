body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

ol, ul {
  padding-left: 30px;
}

.defaultTextbox {
    width: 50px;
    text-align: right;
}
